import React from "react";
// import { Link } from "react-router-dom";

const Contact = (): React.ReactElement => {
	return (
		<section className="contactPage">
			<div className="contactLeft">
				<div className="leftTop">
					<h2>Founder, owner and master printer</h2>
					<p>Dan Benveniste</p>
					<a
						href="mailto:info@benveniste.com? &body=links:  %0D https://www.benveniste.com"
						target="_top"
					>
						info@benveniste.com
					</a>
					<br />
					<br />
					<h2>Master printers</h2>
					<p>Elena Alonso González</p>
					<p style={{ marginBottom: "5px" }}>Usame Habil</p>
					<br />
					{/* <h2>Liaison</h2>
					<p>Margarita González Vázquez</p>
					<a
						href="mailto:margarita.gonzalez@benveniste.com? &body=links:  %0D https://www.benveniste.com"
						target="_top"
					>
						margarita.gonzalez@benveniste.com
					</a>
					<br />
					<br /> */}
					<h2>Location</h2>
					<p>Calle Nicolás Morales, 37</p>
					<p>28019 Madrid [Spain]</p>
					<p>t [+34] 914 718 305</p>
					<p>m [+34] 630 017 923</p>
					<p>CIF [tax reg.] ES B83812529</p>
					<br />
					<h2>Opening hours</h2>
					<p>Monday through Friday 9:30 - 17:00 and by appointment</p>
					<br />
					<h2>Member of</h2>
					<p>
						ArteMadrid, Consorcio de Galerías de Arte Contemporáneo
						and IFPDA
					</p>
					<br />
				</div>
				<div className="leftBottom">
					<br />
					<p>
						All work presented on this website has been produced and
						published exclusively by Benveniste Contemporary
					</p>
					<br />
					<p style={{ display: "inline" }}>
						For further information about the artists and their
						editions, please contact our{" "}
						<a
							style={{ display: "inline" }}
							href="mailto:info@benveniste.com? &body=links:  %0D https://www.benveniste.com"
							target="_top"
						>
							office
						</a>
					</p>
					<br />
					<br />
					<p>© Benveniste Contemporary</p>
					<br />
					<p>
						Benveniste Contemporary also produces editions on
						commission for other publishers and entities. You are
						welcome to contact us for estimates and proposals{" "}
						<a
							style={{ display: "inline" }}
							href="mailto:info@benveniste.com? &body=links:  %0D https://www.benveniste.com"
							target="_top"
						>
							here
						</a>
					</p>
				</div>
			</div>
			<div className="contactRight">
				<img
					src="https://firebasestorage.googleapis.com/v0/b/benveniste-contemporary.appspot.com/o/Contact%2FWorkshop%20cropped.jpg?alt=media&token=948b393e-0c7e-4a8f-9e79-bc5cfec7fae6"
					alt="Pic of the Benveniste Workshop"
				></img>
			</div>
		</section>
	);
};

export default Contact;
