import React, { useEffect, useState } from "react";
import type {
	OnDisplayEvent,
	OnDisplayExhibition,
	OnDisplayNewEdition,
	OnDisplayPSC,
	OnDisplayThumbnail,
	OnDisplayVideo,
	OnDisplayOther,
} from "./interfaces";
import { deleteOnDisplayThumbnail } from "./FirestoreDB";
import {
	extractCapitalLettersAndNormalise,
	normaliseString,
} from "./utilities";
import ErrorPage from "../errorPage";

interface InputFormProps {
	onSubmit: (formData: OnDisplayThumbnail[]) => void;
	currentContent: OnDisplayThumbnail[] | undefined;
}

interface TypeDDMProps {
	currentType: string;
	onSelectType: (selectedType: string) => void;
}

const TypeDropdownMenu: React.FC<TypeDDMProps> = ({
	currentType,
	onSelectType,
}) => {
	const handleSelectType = (
		event: React.ChangeEvent<HTMLSelectElement>,
	): void => {
		onSelectType(event.target.value);
	};

	return (
		<select value={currentType} onChange={handleSelectType}>
			<option key={1} value={"Exhibition"}>
				Exhibition
			</option>
			<option key={2} value={"Event"}>
				Event
			</option>
			<option key={3} value={"NewEdition"}>
				Edition
			</option>
			<option key={4} value={"PSC"}>
				PSC
			</option>
			<option key={5} value={"Video"}>
				Video
			</option>
			<option key={6} value={"Other"}>
				Other
			</option>
		</select>
	);
};

function InputFormOnDisplay({
	onSubmit,
	currentContent,
}: InputFormProps): React.ReactElement {
	const [inputODThumbnails, setInputODThumbnails] = useState<
		OnDisplayThumbnail[]
	>([
		{
			type: "Exhibition",
			heading1: "",
			heading2: "",
			description: "",
			imgSrc: "",
			imgAlt: "",
			path: "",
			hasArtwork: true,
			pageStyle: {
				date: "",
				description: "",
				dossierLink: "",
				PRLink: "",
				artLink: "",
				bannerImage: { src: "", alt: "" },
			},
		},
	]);
	const [expandedThumbnails, setExpandedThumbnails] = useState<boolean[]>([]);

	useEffect(() => {
		if (currentContent !== undefined) {
			setInputODThumbnails(currentContent);
		}
	}, [currentContent]);

	const handleSubmit = (e: React.FormEvent): void => {
		e.preventDefault();

		const updatedODThumbnails = inputODThumbnails.map(
			(ODThumbnail: OnDisplayThumbnail, index) => {
				let artLink: string | undefined;
				let numIdentifier: string;
				if (index < 10) {
					numIdentifier = `000${index}`;
				} else if (index >= 10 && index < 100) {
					numIdentifier = `00${index}`;
				} else if (index >= 100 && index < 1000) {
					numIdentifier = `0${index}`;
				} else {
					numIdentifier = `${index}`;
				}
				if (ODThumbnail.type !== "PSC" && ODThumbnail.hasArtwork) {
					artLink =
						extractCapitalLettersAndNormalise(
							ODThumbnail.heading1,
						) + normaliseString(ODThumbnail.heading2);
				} else if (ODThumbnail.type === "PSC") {
					artLink = undefined;
				} else {
					artLink = "";
				}
				return {
					type: ODThumbnail.type,
					heading1: ODThumbnail.heading1,
					heading2: ODThumbnail.heading2,
					description: ODThumbnail.description,
					imgSrc: ODThumbnail.imgSrc,
					imgAlt: `${ODThumbnail.heading2} Thumbnail Image`,
					path: `OD-${numIdentifier}`,
					hasArtwork: ODThumbnail.hasArtwork,
					pageStyle: {
						...ODThumbnail.pageStyle,
						...(artLink !== undefined && { artLink }),
					},
				};
			},
		);
		setInputODThumbnails(updatedODThumbnails);
		if (
			window.confirm("Confirm submit ? (Refresh page to reset the form)")
		) {
			onSubmit(updatedODThumbnails);
		}
	};

	const handleTypeChange = (selectedType: string, index: number): void => {
		setInputODThumbnails((ODThumbnails) => {
			const updatedODThumbnails = [...ODThumbnails];
			updatedODThumbnails[index].type = selectedType;

			// Update pageStyle based on selectedType
			switch (selectedType) {
				case "Event":
				case "Exhibition":
					updatedODThumbnails[index].pageStyle = {
						date: "",
						description: "",
						dossierLink: "",
						PRLink: "",
						artLink: "",
						bannerImage: {
							src: "",
							alt: "",
						},
					};
					break;
				case "Other":
					updatedODThumbnails[index].pageStyle = {
						title: "",
						customh1: updatedODThumbnails[index].heading1,
						customh2: updatedODThumbnails[index].heading2,
						date: "",
						description: "",
						dossierLink: "",
						PRLink: "",
						artLink: "",
						bannerImage: {
							src: "",
							alt: "",
						},
					};
					break;
				case "Video":
					updatedODThumbnails[index].pageStyle = {
						date: "",
						description: "",
						dossierLink: "",
						PRLink: "",
						artLink: "",
						videoSrc: "",
					};
					break;
				case "NewEdition":
					updatedODThumbnails[index].pageStyle = {
						artLink: "",
					};
					break;
				case "PSC":
					updatedODThumbnails[index].hasArtwork = false;
					updatedODThumbnails[index].pageStyle = {
						PSC_URL: "",
					};
					break;
				default:
					updatedODThumbnails[index].pageStyle = {};
					break;
			}
			return updatedODThumbnails;
		});
	};

	const changeInputByType = (
		ODThumbnail: OnDisplayThumbnail,
		index: number,
	): React.ReactElement => {
		if (ODThumbnail.type === undefined) {
			return (
				<div>
					<p>Type undefined | Error Loading</p>
				</div>
			);
		} else if (
			ODThumbnail.type === "Exhibition" ||
			ODThumbnail.type === "Event"
		) {
			const selectedPageStyle = ODThumbnail.pageStyle as
				| OnDisplayExhibition
				| OnDisplayEvent;

			return (
				<>
					<label key={`Thumbnail${index}DateEntryPoint`}>
						Date:
						<input
							type="text"
							value={selectedPageStyle.date}
							onChange={(e) => {
								setInputODThumbnails((thumbnails) => {
									const newThumbnails = [...thumbnails];
									newThumbnails[index] = {
										...newThumbnails[index],
										pageStyle: {
											...newThumbnails[index].pageStyle,
											date: e.target.value,
										},
									};
									return newThumbnails;
								});
							}}
						/>
					</label>
					<label key={`Thumbnail${index}DossierLinkEntryPoint`}>
						Link to Dossier (Firebase Access Code)
						<input
							type="text"
							value={selectedPageStyle.dossierLink}
							onChange={(e) => {
								setInputODThumbnails((thumbnails) => {
									const newThumbnails = [...thumbnails];
									newThumbnails[index] = {
										...newThumbnails[index],
										pageStyle: {
											...newThumbnails[index].pageStyle,
											dossierLink: e.target.value,
										},
									};
									return newThumbnails;
								});
							}}
						/>
					</label>
					<label key={`Thumbnail${index}PRLinkEntryPoint`}>
						Link to Press Release (Firebase Access Code)
						<input
							type="text"
							value={selectedPageStyle.PRLink}
							onChange={(e) => {
								setInputODThumbnails((thumbnails) => {
									const newThumbnails = [...thumbnails];
									newThumbnails[index] = {
										...newThumbnails[index],
										pageStyle: {
											...newThumbnails[index].pageStyle,
											PRLink: e.target.value,
										},
									};
									return newThumbnails;
								});
							}}
						/>
					</label>
					<label key={`Thumbnail${index}BannerImgSrcEntryPoint`}>
						Page Banner Image URL (FirebaseURL):
						<input
							type="text"
							value={selectedPageStyle.bannerImage.src}
							onChange={(e) => {
								setInputODThumbnails((thumbnails) => {
									const newThumbnails = [...thumbnails];
									newThumbnails[index] = {
										...newThumbnails[index],
										pageStyle: {
											...newThumbnails[index].pageStyle,
											bannerImage: {
												...selectedPageStyle.bannerImage, // Update only the bannerImg part
												src: e.target.value,
												alt: `Representative Image for ${ODThumbnail.heading2} page`,
											},
										},
									};
									return newThumbnails;
								});
							}}
						/>
					</label>
					<label>
						Enter description (for page)
						<br></br>
						<em>
							Available Styles = Italic: &lt;em&gt;&lt;/em&gt; |
							Bold: &lt;bold&gt;&lt;/bold&gt; | Break: &lt;br
							/&gt;
						</em>
						<em>
							Note: Use "&lt;em&gt;...&lt;/em&gt;" for quotes and
							don't foret to close tags at every paragraph
						</em>
						<textarea
							placeholder="Enter long description for the page..."
							// maxLength={150}
							className="descriptionPage"
							value={selectedPageStyle.description}
							onChange={(e) => {
								setInputODThumbnails((thumbnails) => {
									const newThumbnails = [...thumbnails];
									newThumbnails[index] = {
										...newThumbnails[index],
										pageStyle: {
											...newThumbnails[index].pageStyle,
											description: e.target.value,
										},
									};
									return newThumbnails;
								});
							}}
						/>
					</label>
				</>
			);
		} else if (ODThumbnail.type === "Other") {
			const selectedPageStyle = ODThumbnail.pageStyle as OnDisplayOther;
			return (
				<>
					<label key={`Thumbnail${index}TitleEntryPoint`}>
						Title of the Project (For above the image on the OD
						page)
						<input
							type="text"
							value={selectedPageStyle.ODtitle}
							onChange={(e) => {
								setInputODThumbnails((thumbnails) => {
									const newThumbnails = [...thumbnails];
									newThumbnails[index] = {
										...newThumbnails[index],
										pageStyle: {
											...newThumbnails[index].pageStyle,
											ODtitle: e.target.value,
										},
									};
									return newThumbnails;
								});
							}}
						/>
					</label>
					<label key={`Thumbnail${index}CustomH1EntryPoint`}>
						Custom Header 1 for the item page (Can be anything!)
						<input
							type="text"
							value={selectedPageStyle.customH1}
							onChange={(e) => {
								setInputODThumbnails((thumbnails) => {
									const newThumbnails = [...thumbnails];
									newThumbnails[index] = {
										...newThumbnails[index],
										pageStyle: {
											...newThumbnails[index].pageStyle,
											customH1: e.target.value,
										},
									};
									return newThumbnails;
								});
							}}
						/>
					</label>
					<label key={`Thumbnail${index}CustomH2EntryPoint`}>
						Custom Header 2 for the item's page (Leave blank to
						remove the second part of the title)
						<input
							type="text"
							value={selectedPageStyle.customH2}
							onChange={(e) => {
								setInputODThumbnails((thumbnails) => {
									const newThumbnails = [...thumbnails];
									newThumbnails[index] = {
										...newThumbnails[index],
										pageStyle: {
											...newThumbnails[index].pageStyle,
											customH2: e.target.value,
										},
									};
									return newThumbnails;
								});
							}}
						/>
					</label>
					<label key={`Thumbnail${index}DateEntryPoint`}>
						Date:
						<input
							type="text"
							value={selectedPageStyle.date}
							onChange={(e) => {
								setInputODThumbnails((thumbnails) => {
									const newThumbnails = [...thumbnails];
									newThumbnails[index] = {
										...newThumbnails[index],
										pageStyle: {
											...newThumbnails[index].pageStyle,
											date: e.target.value,
										},
									};
									return newThumbnails;
								});
							}}
						/>
					</label>
					<label key={`Thumbnail${index}DossierLinkEntryPoint`}>
						Link to Dossier (Firebase Access Code)
						<input
							type="text"
							value={selectedPageStyle.dossierLink}
							onChange={(e) => {
								setInputODThumbnails((thumbnails) => {
									const newThumbnails = [...thumbnails];
									newThumbnails[index] = {
										...newThumbnails[index],
										pageStyle: {
											...newThumbnails[index].pageStyle,
											dossierLink: e.target.value,
										},
									};
									return newThumbnails;
								});
							}}
						/>
					</label>
					<label key={`Thumbnail${index}PRLinkEntryPoint`}>
						Link to Press Release (Firebase Access Code)
						<input
							type="text"
							value={selectedPageStyle.PRLink}
							onChange={(e) => {
								setInputODThumbnails((thumbnails) => {
									const newThumbnails = [...thumbnails];
									newThumbnails[index] = {
										...newThumbnails[index],
										pageStyle: {
											...newThumbnails[index].pageStyle,
											PRLink: e.target.value,
										},
									};
									return newThumbnails;
								});
							}}
						/>
					</label>
					<label key={`Thumbnail${index}BannerImgSrcEntryPoint`}>
						Page Banner Image URL (FirebaseURL):
						<input
							type="text"
							value={selectedPageStyle.bannerImage.src}
							onChange={(e) => {
								setInputODThumbnails((thumbnails) => {
									const newThumbnails = [...thumbnails];
									newThumbnails[index] = {
										...newThumbnails[index],
										pageStyle: {
											...newThumbnails[index].pageStyle,
											bannerImage: {
												...selectedPageStyle.bannerImage, // Update only the bannerImg part
												src: e.target.value,
												alt: `Representative Image for ${ODThumbnail.heading2} page`,
											},
										},
									};
									return newThumbnails;
								});
							}}
						/>
					</label>
					<label>
						Enter description (for page)
						<br></br>
						<em>
							Available Styles = Italic: &lt;em&gt;&lt;/em&gt; |
							Bold: &lt;bold&gt;&lt;/bold&gt; | Break: &lt;br
							/&gt;
						</em>
						<em>
							Note: Use "&lt;em&gt;...&lt;/em&gt;" for quotes and
							don't foret to close tags at every paragraph
						</em>
						<textarea
							placeholder="Enter long description for the page..."
							// maxLength={150}
							className="descriptionPage"
							value={selectedPageStyle.description}
							onChange={(e) => {
								setInputODThumbnails((thumbnails) => {
									const newThumbnails = [...thumbnails];
									newThumbnails[index] = {
										...newThumbnails[index],
										pageStyle: {
											...newThumbnails[index].pageStyle,
											description: e.target.value,
										},
									};
									return newThumbnails;
								});
							}}
						/>
					</label>
				</>
			);
		} else if (ODThumbnail.type === "PSC") {
			const selectedPageStyle = ODThumbnail.pageStyle as OnDisplayPSC;
			return (
				<>
					<label key={`Thumbnail${index}NewPSCLinkEntryPoint`}>
						Link to New PSC (Search bar URL to that address)
						<input
							type="text"
							value={selectedPageStyle.PSC_URL}
							onChange={(e) => {
								setInputODThumbnails((thumbnails) => {
									const newThumbnails = [...thumbnails];
									newThumbnails[index] = {
										...newThumbnails[index],
										pageStyle: {
											...newThumbnails[index].pageStyle,
											PSC_URL: e.target.value,
										},
									};
									return newThumbnails;
								});
							}}
						/>
					</label>
				</>
			);
		} else if (ODThumbnail.type === "NewEdition") {
			const selectedPageStyle =
				ODThumbnail.pageStyle as OnDisplayNewEdition;
			selectedPageStyle.artLink =
				extractCapitalLettersAndNormalise(ODThumbnail.heading1) +
				normaliseString(ODThumbnail.heading2);
			return <></>;
		} else if (ODThumbnail.type === "Video") {
			const selectedPageStyle = ODThumbnail.pageStyle as OnDisplayVideo;
			return (
				<>
					<label key={`Thumbnail${index}VideoSrcEntryPoint`}>
						Video Src - Link to Youtube / Firebase
						<input
							type="text"
							value={selectedPageStyle.videoSrc}
							onChange={(e) => {
								setInputODThumbnails((thumbnails) => {
									const newThumbnails = [...thumbnails];
									newThumbnails[index] = {
										...newThumbnails[index],
										pageStyle: {
											...newThumbnails[index].pageStyle,
											videoSrc: e.target.value,
										},
									};
									return newThumbnails;
								});
							}}
						/>
					</label>
					<label key={`Thumbnail${index}NewPSCLinkEntryPoint`}>
						Video description for the page
						<br></br>
						<em>
							Available Styles = Italic: &lt;em&gt;&lt;/em&gt; |
							Bold: &lt;bold&gt;&lt;/bold&gt; | Break: &lt;br
							/&gt;
						</em>
						<em>
							Note: Use "&lt;em&gt;...&lt;/em&gt;" for quotes and
							don't foret to close tags at every paragraph
						</em>
						<textarea
							placeholder="Enter the full description for the video page..."
							className="descriptionPage"
							value={selectedPageStyle.description}
							onChange={(e) => {
								setInputODThumbnails((thumbnails) => {
									const newThumbnails = [...thumbnails];
									newThumbnails[index] = {
										...newThumbnails[index],
										pageStyle: {
											...newThumbnails[index].pageStyle,
											description: e.target.value,
										},
									};
									return newThumbnails;
								});
							}}
						/>
					</label>
					<label key={`Thumbnail${index}DateEntryPoint`}>
						Date:
						<input
							type="text"
							value={selectedPageStyle.date}
							onChange={(e) => {
								setInputODThumbnails((thumbnails) => {
									const newThumbnails = [...thumbnails];
									newThumbnails[index] = {
										...newThumbnails[index],
										pageStyle: {
											...newThumbnails[index].pageStyle,
											date: e.target.value,
										},
									};
									return newThumbnails;
								});
							}}
						/>
					</label>
					<label key={`Thumbnail${index}DossierLinkEntryPoint`}>
						Link to Dossier (Firebase Access Code)
						<input
							type="text"
							value={selectedPageStyle.dossierLink}
							onChange={(e) => {
								setInputODThumbnails((thumbnails) => {
									const newThumbnails = [...thumbnails];
									newThumbnails[index] = {
										...newThumbnails[index],
										pageStyle: {
											...newThumbnails[index].pageStyle,
											dossierLink: e.target.value,
										},
									};
									return newThumbnails;
								});
							}}
						/>
					</label>
					<label key={`Thumbnail${index}PRLinkEntryPoint`}>
						Link to Press Release (Firebase Access Code)
						<input
							type="text"
							value={selectedPageStyle.PRLink}
							onChange={(e) => {
								setInputODThumbnails((thumbnails) => {
									const newThumbnails = [...thumbnails];
									newThumbnails[index] = {
										...newThumbnails[index],
										pageStyle: {
											...newThumbnails[index].pageStyle,
											PRLink: e.target.value,
										},
									};
									return newThumbnails;
								});
							}}
						/>
					</label>
				</>
			);
		} else {
			return (
				<>
					<ErrorPage></ErrorPage>
				</>
			);
		}
	};

	const handleAddODThumbnail = (): void => {
		setInputODThumbnails((ODThumbnails) => [
			...ODThumbnails,
			{
				type: "Exhibition",
				heading1: "",
				heading2: "",
				description: "",
				imgSrc: "",
				imgAlt: "",
				path: "",
				hasArtwork: true,
				pageStyle: {
					date: "",
					description: "",
					dossierLink: "",
					PRLink: "",
					artLink: "",
					bannerImage: {
						src: "",
						alt: "",
					},
				},
			},
		]);
	};

	const handleToggleExpand = (thumbnailIndex: number): void => {
		setExpandedThumbnails((prevState) => {
			const newState = [...prevState];
			newState[thumbnailIndex] = !newState[thumbnailIndex];
			return newState;
		});
	};

	const handleRemoveODThumbnail = async (
		thumbnailIndex: number,
		thumbnailToDelete: OnDisplayThumbnail,
	): Promise<void> => {
		if (
			window.confirm(
				"Are you sure you want to delete this thumbnail? (This will delete immediately!)",
			)
		) {
			try {
				// Delete the thumbnail from Firestore
				await deleteOnDisplayThumbnail(thumbnailToDelete);

				// Update the state by removing the deleted thumbnail
				setInputODThumbnails((ODThumbnails) => {
					const newODThumbnails = [...ODThumbnails];
					newODThumbnails.splice(thumbnailIndex, 1);
					return newODThumbnails;
				});
			} catch (error) {
				console.error("Error deleting thumbnail:", error);
			}
		}
	};

	function handleRemoveODThumbnailClick(
		thumbnailIndex: number,
		thumbnailToDelete: OnDisplayThumbnail,
	): void {
		console.log("test");
		handleRemoveODThumbnail(thumbnailIndex, thumbnailToDelete)
			.then(() => {
				console.log("Thumbnail content successfully deleted!");
			})
			.catch((error) => {
				console.error("Error deleting thumbnail:", error);
			});
	}

	const handleMoveODThumbnail = (
		thumbnailIndex: number,
		moveUp: boolean,
	): void => {
		setInputODThumbnails((ODThumbnails) => {
			const newODThumbnails = [...ODThumbnails];
			if (moveUp) {
				if (thumbnailIndex > 0) {
					// swap the current set with the one above it
					[
						newODThumbnails[thumbnailIndex - 1],
						newODThumbnails[thumbnailIndex],
					] = [
						newODThumbnails[thumbnailIndex],
						newODThumbnails[thumbnailIndex - 1],
					];
				}
			} else {
				if (thumbnailIndex < newODThumbnails.length - 1) {
					// swap the current set with the one below it
					[
						newODThumbnails[thumbnailIndex],
						newODThumbnails[thumbnailIndex + 1],
					] = [
						newODThumbnails[thumbnailIndex + 1],
						newODThumbnails[thumbnailIndex],
					];
				}
			}
			return newODThumbnails;
		});
	};
	return (
		<form onSubmit={handleSubmit}>
			{inputODThumbnails.map((ODThumbnail, index) => (
				<div key={`Thumbnail${index}OuterDiv`}>
					<div
						key={index}
						style={{ cursor: "pointer" }}
						onClick={() => {
							handleToggleExpand(index);
						}}
					>
						<h3>
							{ODThumbnail.path +
								" " +
								normaliseString(ODThumbnail.heading1) +
								" "}
							{expandedThumbnails[index] ? "↓" : "↑"}
						</h3>
					</div>
					<button
						type="button"
						onClick={() => {
							handleRemoveODThumbnailClick(index, ODThumbnail);
						}}
					>
						Remove
					</button>
					<button
						type="button"
						onClick={() => {
							handleMoveODThumbnail(index, true);
						}}
					>
						Move Up
					</button>
					<button
						type="button"
						onClick={() => {
							handleMoveODThumbnail(index, false);
						}}
					>
						Move down
					</button>
					{expandedThumbnails[index] && (
						<div key={`ODThumbnail${index}`}>
							<TypeDropdownMenu
								key={`ODThumbnail${index}Type`}
								currentType={ODThumbnail.type}
								onSelectType={(selectedType) => {
									handleTypeChange(selectedType, index);
								}}
							></TypeDropdownMenu>
							<label key={`Thumbnail${index}Heading1EntryPoint`}>
								Heading 1 (i.e. bold artist name): [Match with
								the page for art link!]
								<input
									type="text"
									value={ODThumbnail.heading1}
									onChange={(e) => {
										setInputODThumbnails((thumbnails) => {
											const newThumbnails = [
												...thumbnails,
											];
											newThumbnails[index].heading1 =
												e.target.value;
											return newThumbnails;
										});
									}}
								/>
							</label>
							<label key={`Thumbnail${index}Heading2EntryPoint`}>
								Heading 2 (i.e. title of work / item): [Match
								with the page for art link!]
								<input
									type="text"
									value={ODThumbnail.heading2}
									onChange={(e) => {
										setInputODThumbnails((thumbnails) => {
											const newThumbnails = [
												...thumbnails,
											];
											newThumbnails[index].heading2 =
												e.target.value;
											return newThumbnails;
										});
									}}
								/>
							</label>
							<label key={`Thumbnail${index}ImgSrcEntryPoint`}>
								Lead Image URL (FirebaseURL):
								<input
									type="text"
									value={ODThumbnail.imgSrc}
									onChange={(e) => {
										setInputODThumbnails((thumbnails) => {
											const newThumbnails = [
												...thumbnails,
											];
											newThumbnails[index].imgSrc =
												e.target.value;
											return newThumbnails;
										});
									}}
								/>
							</label>
							<label
								key={`Thumbnail${index}ThumbnailDescriptionEntryPoint`}
							>
								Brief description for On Display | 300 words
								max:
								<textarea
									placeholder="Enter brief description for the on display page..."
									maxLength={300}
									className="descriptionBrief"
									value={ODThumbnail.description}
									onChange={(e) => {
										setInputODThumbnails((thumbnails) => {
											const newThumbnails = [
												...thumbnails,
											];
											newThumbnails[index].description =
												e.target.value;
											return newThumbnails;
										});
									}}
								/>
							</label>
							<>
								{ODThumbnail.type !== "PSC" && (
									<label>
										Artwork Available:
										<input
											type="checkbox"
											checked={ODThumbnail.hasArtwork}
											onChange={(e) => {
												setInputODThumbnails(
													(thumbnails) => {
														const newThumbnails = [
															...thumbnails,
														];
														newThumbnails[
															index
														].hasArtwork =
															e.target.checked;
														return newThumbnails;
													},
												);
											}}
										/>
									</label>
								)}
							</>
							{changeInputByType(ODThumbnail, index)}
						</div>
					)}
				</div>
			))}
			<button
				type="button"
				onClick={() => {
					handleAddODThumbnail();
				}}
			>
				Add new
			</button>
			<button
				type="button"
				onClick={(event) => {
					handleSubmit(event);
				}}
			>
				Submit
			</button>
		</form>
	);
}

export default InputFormOnDisplay;
