import React, { useEffect, useState } from "react";
import type { ReactElement } from "react";
import type {
	OnDisplayEvent,
	OnDisplayExhibition,
	OnDisplayThumbnail,
	OnDisplayVideo,
	OnDisplayOther,
} from "./interfaces";
import { useParams, Link, useNavigate } from "react-router-dom";
import { readSingleThumbnail } from "./FirestoreDB";
import ErrorPage from "../errorPage";
import { normaliseString, textFormatter } from "./utilities";

const BackButton: React.FC = (): ReactElement => {
	const history = useNavigate();

	const goBack = (): void => {
		history(-1);
	};

	return (
		<button className="backButton" onClick={goBack}>
			←
		</button>
	);
};

export const DisplayODContent: React.FC = () => {
	const [thumbnailContent, setThumbnailContent] = useState<
		OnDisplayThumbnail | undefined
	>(undefined);
	const thumbnailPath = useParams().pathName;

	useEffect(() => {
		const fetchData = async (): Promise<OnDisplayThumbnail | undefined> =>
			await readSingleThumbnail(thumbnailPath);

		fetchData()
			.then((thumbnailData) => {
				setThumbnailContent(thumbnailData);
				console.log("Thumbnail content successfully loaded!");
			})
			.catch((error) => {
				console.error("Error fetching thumbnail content!", {
					error,
				});
			});
	}, [thumbnailPath]);

	let contentRenderer;
	if (thumbnailContent !== undefined) {
		let currentPageStyle;
		switch (thumbnailContent.type) {
			case "Exhibition":
			case "Event":
			case "Other":
				currentPageStyle = thumbnailContent.pageStyle as
					| OnDisplayExhibition
					| OnDisplayEvent
					| OnDisplayOther;
				contentRenderer = (
					<div className="ODContentContainer">
						<div className="ODContentTextBox">
							<BackButton></BackButton>
							<div className="titles">
								{thumbnailContent.type !== "Other" && (
									<>
										<h1>{thumbnailContent.heading1}</h1>
										<h2>_</h2>
										<h2>{thumbnailContent.heading2}</h2>
									</>
								)}
								{thumbnailContent.type === "Other" &&
									"customH1" in currentPageStyle && (
										<>
											<h1>{currentPageStyle.customH1}</h1>
											{currentPageStyle.customH2 !==
												"" && <h2>_</h2>}
											{currentPageStyle.customH2 !==
												"" && (
												<h2>
													{currentPageStyle.customH2}
												</h2>
											)}
										</>
									)}
							</div>
							<br></br>
							<h3>{currentPageStyle.date}</h3>
							<br></br>
							<article>
								<p>
									{textFormatter(
										currentPageStyle.description,
									)}
								</p>
							</article>
							{currentPageStyle.dossierLink !== "" && (
								<a
									href={currentPageStyle.dossierLink}
									target="_blank"
									rel="noopener noreferrer"
									className="link"
								>
									Download dossier
								</a>
							)}
							{currentPageStyle.PRLink !== "" && (
								<a
									href={currentPageStyle.PRLink}
									target="_blank"
									rel="noopener noreferrer"
									className="link"
								>
									Download press release
								</a>
							)}
							{currentPageStyle.artLink !== "" && (
								<Link
									to={`/artists/${normaliseString(
										thumbnailContent.heading1,
									)}#${currentPageStyle.artLink}`}
									className="link"
								>
									See artwork
								</Link>
							)}
						</div>
						<div className="ODContentImageBox">
							<img
								src={currentPageStyle.bannerImage.src}
								alt={currentPageStyle.bannerImage.alt}
							/>
							<div className="ODImageCaption">
								<p>© Benveniste Contemporary</p>
							</div>
						</div>
					</div>
				);
				break;
			case "Video":
				currentPageStyle = thumbnailContent.pageStyle as OnDisplayVideo;
				contentRenderer = (
					<div className="ODContentContainer">
						<div className="ODContentTextBox">
							<BackButton></BackButton>
							<div className="titles">
								<h1>{thumbnailContent.heading1}</h1>
								<h2>_{thumbnailContent.heading2}</h2>
							</div>
							<br></br>
							<h3>{currentPageStyle.date}</h3>
							<br></br>
							<article>
								<p>
									{textFormatter(
										currentPageStyle.description,
									)}
								</p>
							</article>
							{(currentPageStyle.dossierLink !== "" ||
								currentPageStyle.dossierLink !== undefined) && (
								<Link
									to={currentPageStyle.dossierLink}
									className="link"
								>
									Download dossier
								</Link>
							)}
							{(currentPageStyle.PRLink !== "" ||
								currentPageStyle.PRLink !== undefined) && (
								<Link
									to={currentPageStyle.PRLink}
									className="link"
								>
									Download press release
								</Link>
							)}
							{(currentPageStyle.artLink !== "" ||
								currentPageStyle.artLink !== undefined) && (
								<Link
									to={`/artists/${normaliseString(
										thumbnailContent.heading1,
									)}#${currentPageStyle.artLink}`}
									className="link"
								>
									See artwork
								</Link>
							)}
						</div>
						<div className="ODContentImageBox">
							{/* <iframe
								width="1217"
								height="685"
								src={currentPageStyle.videoSrc}
								title=""
								frameBorder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
								allowFullScreen
							></iframe> */}
							<iframe
								width="2000"
								height="494"
								src={currentPageStyle.videoSrc}
								title={thumbnailContent.heading2}
								frameBorder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
								allowFullScreen
							></iframe>
							{/* <iframe
								autoPlay
								loop
								controls
								width="105%"
								height="100%"
								muted
							>
								<source
									src={currentPageStyle.videoSrc}
									type="video/mp4"
								/>
								Issue loading header
							</video> */}
							<div className="ODImageCaption">
								<p>© Benveniste Contemporary</p>
							</div>
						</div>
					</div>
				);
				break;
			case "PSC":
			case "Edition":
			default:
				contentRenderer = (
					<>
						<ErrorPage></ErrorPage>
					</>
				);
				break;
		}
	} else {
		return (
			<>
				<ErrorPage></ErrorPage>
			</>
		);
	}

	return <section className="OnDisplayItemPage">{contentRenderer}</section>;
};

export default DisplayODContent;
